import type { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, FormLabel } from "components";
import { setUserInformationsDetailsMutation } from "core";
import { useMutation } from "react-query";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/RadixAccordion";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { CustomInput } from "components/CustomInput";
import { selectComponents } from "core/helpers/form";
import CreatableSelect from "react-select/creatable";
import { Option } from "@types";

type InputProps = {
  type: "text" | "date" | "number" | "file";
  label?: "street" | "zipCode" | "city" | "country";
  step?: number;
};

const homeAdressFormData = {
  street: {
    type: "text",
  },
  zipCode: {
    type: "text",
  },
  city: {
    type: "text",
  },
  country: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

const workAdressFormData = {
  workStreet: {
    type: "text",
    label: "street",
  },
  workZipCode: {
    type: "text",
    label: "zipCode",
  },
  workCity: {
    type: "text",
    label: "city",
  },
  workCountry: {
    type: "text",
    label: "country",
  },
} satisfies Record<string, InputProps>;

const otherAdressFormData = {
  otherStreet: {
    label: "street",
    type: "text",
  },
  otherZipCode: {
    type: "text",
    label: "zipCode",
  },
  otherCity: {
    label: "city",
    type: "text",
  },
  otherCountry: {
    label: "country",
    type: "text",
  },
} satisfies Record<string, InputProps>;

const phoneFormData = {
  firstPhoneNumber: {
    type: "text",
  },
  secondPhoneNumber: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

const emailsFormData = {
  personalEmail: {
    type: "text",
  },
  otherEmail: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

export type DetailInformationsFormDataType = Record<
  keyof typeof homeAdressFormData,
  string | number
> &
  Record<keyof typeof workAdressFormData, string | number> &
  Record<keyof typeof otherAdressFormData, string | number> &
  Record<keyof typeof emailsFormData, string | number> &
  Record<keyof typeof phoneFormData, number> & {
    fiscalAddress: string;
  };

export const ProfileInformationsDetails: FC<{ defaultValues?: object }> = ({
  defaultValues,
}) => {
  const mutation = useMutation(
    (input: Record<string, any>) =>
      setUserInformationsDetailsMutation({ input }),
    {
      onSuccess: () => {
        toast.success(t("notification.save"));
      },
    }
  );

  const { t } = useTranslation();

  const form = useForm<DetailInformationsFormDataType>({
    defaultValues,
  });

  const formValues = form.getValues();

  const countries = t("countries", { returnObjects: true }) || {};
  const countriesOptions: Option[] = Object.entries(countries).map(
    ([value, label]: [string, string]) => ({
      value,
      label,
    })
  );

  return (
    <form onSubmit={form.handleSubmit((data) => mutation.mutate(data))}>
      <Accordion type="multiple" className="w-full" defaultValue={["item-1"]}>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            {t("forms.customers.details.details.accordion.homeAddress")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4  overflow-visible">
            <CustomInput
              type={"text"}
              key={"street"}
              {...form.register("street")}
              label={t(`forms.customers.details.details.street`)}
            />
            <CustomInput
              type={"text"}
              key={"zipCode"}
              {...form.register("zipCode")}
              label={t(`forms.customers.details.details.zipCode`)}
            />
            <CustomInput
              type={"text"}
              key={"city"}
              {...form.register("city")}
              label={t(`forms.customers.details.details.city`)}
            />
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.details.country"
                name="country"
              />
              <Controller
                key={"country"}
                {...form.register("country")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="nationality"
                    value={countriesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t("forms.customers.details.details.country")}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={countriesOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger>
            {t("forms.customers.details.details.accordion.workAddress")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4 overflow-visible">
            <CustomInput
              type={"text"}
              key={"workStreet"}
              {...form.register("workStreet")}
              label={t(`forms.customers.details.details.street`)}
            />
            <CustomInput
              type={"text"}
              key={"workZipCode"}
              {...form.register("workZipCode")}
              label={t(`forms.customers.details.details.zipCode`)}
            />
            <CustomInput
              type={"text"}
              key={"workCity"}
              {...form.register("workCity")}
              label={t(`forms.customers.details.details.city`)}
            />
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.details.country"
                name="workCountry"
              />
              <Controller
                key={"workCountry"}
                {...form.register("workCountry")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="nationality"
                    value={countriesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t("forms.customers.details.details.country")}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={countriesOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger>
            {t("forms.customers.details.details.accordion.otherAddress")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4 overflow-visible">
            <CustomInput
              type={"text"}
              key={"otherStreet"}
              {...form.register("otherStreet")}
              label={t(`forms.customers.details.details.street`)}
            />
            <CustomInput
              type={"text"}
              key={"otherZipCode"}
              {...form.register("otherZipCode")}
              label={t(`forms.customers.details.details.zipCode`)}
            />
            <CustomInput
              type={"text"}
              key={"otherCity"}
              {...form.register("otherCity")}
              label={t(`forms.customers.details.details.city`)}
            />

            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.details.country"
                name="otherCountry"
              />
              <Controller
                key={"otherCountry"}
                {...form.register("otherCountry")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="nationality"
                    value={countriesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t("forms.customers.details.details.country")}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={countriesOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-6">
          <AccordionTrigger>
            {t("forms.customers.details.details.accordion.fiscalAddress")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            <CustomInput
              type="text"
              {...form.register("fiscalAddress")}
              defaultValue={formValues["fiscalAddress"]}
              label={t(
                "forms.customers.details.details.accordion.fiscalAddress"
              )}
            />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger>
            {t("forms.customers.details.details.accordion.phone")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(phoneFormData).map(([key, value]) => (
              <CustomInput
                type={value.type}
                key={key}
                {...form.register(key)}
                label={t(`forms.customers.details.details.${key}`)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-5">
          <AccordionTrigger>
            {t("forms.customers.details.details.accordion.emails")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(emailsFormData).map(([key, value]) => (
              <CustomInput
                type={value.type}
                key={key}
                {...form.register(key)}
                disabled={key === "personalEmail"}
                label={t(`forms.customers.details.details.${key}`)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Button
        label="actions.save"
        className="mt-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-400 px-4 py-2 font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        type="submit"
      />
    </form>
  );
};
